import { Col, Row } from "antd";
import styled, { css } from "styled-components";

export const Custom404Container = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  ${({ theme }) =>
    theme.mode === "dark"
      ? css`
          h1 {
            color: #ffffff !important;
          }
        `
      : css`
          div {
            color: #545454;
          }
        `}
`;

export const Title = styled(Col)`
  display: flex;
  justify-content: center;
  color: #bdbdbd;
  text-transform: uppercase;
  margin-bottom: 15px !important;
`;

export const ColContent = styled(Col)<{ button: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bdbdbd;

  ${({ button }) =>
    button === "true" &&
    css`
      margin-top: 20px !important;
      margin-bottom: 10px !important;
    `}
`;

export const PrimaryButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.textMasterColor};
  border: none;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 8px 12px;
  height: 36px;

  :hover {
    cursor: pointer;
  }
`;
